// extracted by mini-css-extract-plugin
export var breadcrumb = "CaseStudy-module--breadcrumb--ckMoo";
export var canopyGreen = "CaseStudy-module--canopyGreen--F7s9T";
export var carbonGrey = "CaseStudy-module--carbonGrey--OO0J2";
export var caseStudyContainer = "CaseStudy-module--caseStudyContainer--PQAuU";
export var ctaContainer = "CaseStudy-module--ctaContainer--67wL+";
export var ctaContent = "CaseStudy-module--ctaContent--xFfdF";
export var ctaImage = "CaseStudy-module--ctaImage---E125";
export var dawnPink = "CaseStudy-module--dawnPink--M7pr4";
export var detailContainer = "CaseStudy-module--detailContainer--EX1kC";
export var detailContent = "CaseStudy-module--detailContent--aQPTp";
export var detailImage = "CaseStudy-module--detailImage--dwJ8b";
export var downloadFormCheckbox = "CaseStudy-module--downloadFormCheckbox--suBmd";
export var downloadFormContainer = "CaseStudy-module--downloadFormContainer--YnE9f";
export var downloadFormContent = "CaseStudy-module--downloadFormContent--Y1Nyc";
export var hiveYellow = "CaseStudy-module--hiveYellow--ItZFV";
export var horizonWhite = "CaseStudy-module--horizonWhite--iWBDk";
export var oceanicBlue = "CaseStudy-module--oceanicBlue--rCjBx";
export var page404Title = "CaseStudy-module--page404-title--5XOp0";