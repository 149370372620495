import React from "react";
import parse from "html-react-parser";
import { Button } from "../../components/Button/Button";

const styles = require("./index.module.scss");

const ImageCopyPanel = (props) => {
  const {
    panel: { copy, image, imagePosition, colorTheme, cta },
    buttonClick,
  } = props;

  const imagePos = styles[imagePosition];

  return (
    <div className={`${styles.panel} bkg-${colorTheme} ${imagePos}`}>
      <div className={styles.panelCopy}>
        {parse(copy)}
        {cta ? (
          <Button
            bkgColor="black"
            label={cta.text}
            icon={cta.icon?.localFile?.publicURL}
            handleOnClick={buttonClick ? buttonClick : null}
          />
        ) : null}
      </div>
      <div className={styles.panelImage}>
        <img src={image.localFile.publicURL} alt={image.alternativeText} />
      </div>
    </div>
  );
};

export default ImageCopyPanel;
