import React, { useRef } from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../../components/Button/Button";
import ImageCopyPanel from "../../components/ImageCopyPanel";
import parse from "html-react-parser";
import HubspotForm from "../../components/HubspotForm/HubspotForm";
import "intersection-observer";

import {
  breadcrumb,
  caseStudyContainer,
  ctaContainer,
  ctaContent,
  ctaImage,
  downloadFormContainer,
  downloadFormContent,
} from "./CaseStudy.module.scss";

const CaseStudyTemplate = (props) => {
  const {
    article: {
      mediaType,
      headingText,
      headerCopy,
      hubspotCode,
      confirmationMsg,
      mainImage,
      detailPanel,
      publish_at,
      metaData,
    },
  } = props;

  const formRef = useRef(null);
  const executeScroll = () => formRef.current.scrollIntoView();

  const mediaHeader =
    mediaType === "CaseStudy" ? "Case Studies" : "Latest Insights";

  let link = "/insights/latest-insights/";

  if (mediaType === "CaseStudy") link = "/insights/case-studies/";

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: mainImage?.localFile?.publicURL,
        article: true,
        noindex: new Date(publish_at) > Date.now() ? true : false,
      }}
    >
      <div className={caseStudyContainer}>
        <section className={ctaContainer}>
          <div className={ctaContent}>
            <Link className={breadcrumb} to={link}>
              {mediaHeader}
            </Link>
            <h3>{headingText}</h3>
            {parse(headerCopy)}
            <Button
              bkgColor="blackOnLight"
              label={`Download the ${
                mediaType.toLowerCase() === "casestudy"
                  ? "case study"
                  : mediaType.toLowerCase()
              }`}
              handleOnClick={executeScroll}
            />
          </div>
          <GatsbyImage
            className={ctaImage}
            image={mainImage?.localFile?.childImageSharp?.gatsbyImageData}
            alt={mainImage?.alternativeText}
          />
        </section>

        <section>
          <ImageCopyPanel panel={detailPanel} />
        </section>

        <section ref={formRef} className={downloadFormContainer}>
          <div className={downloadFormContent}>
            <HubspotForm
              confirmationMessage={confirmationMsg}
              formId={hubspotCode}
              alwaysConsent={true}
              selectStyles={{
                option: (provided) => ({
                  ...provided,
                  color: "black",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#2d2d2d",
                  opacity: 0.4,
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#2d2d2d",
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  color: "#2d2d2d",
                  height: "50px",
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  border: "1px solid #2d2d2d",
                  width: "100%",
                  color: "white",
                  borderRadius: "0",
                  marginBottom: "10px",
                }),
              }}
              title={`Download the ${
                mediaType.toLowerCase() === "casestudy"
                  ? "case study"
                  : mediaType.toLowerCase()
              }`}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CaseStudyTemplate;
